<template>
<div>
    <div class="headerText"><strong>My Profile</strong></div>
    <div class="card-panel">
        <div class="profile">
            <div class="myProfDetails center">
                    <img class="User-icon" :src="FileFavIcon" style="width: 150px; height: 150px" />
                    <div class="ChangeProfile" style="display:flex; justify-content: space-between;padding-top: 15px;">
                        <div class="ChangeProfile">
                            <label for="file1" style="font-size: 20px; color: #6D2D7E;cursor:pointer;">
                                <input id="file1" type="file" style="display:none;" @change="onUploadFile($event)" accept="image/*" />
                                <i class="icon material-icons">edit</i>Change
                            </label></div>
                        <div class="ChangeProfile" @click="removeIcon"><i class="icon material-icons">delete</i><a style="color: #6D2D7E;">Remove</a></div>
                    </div>
                    <div v-if="UploadButton" class="center">
                        <a class="UserProfile waves-effect waves-light btn getclassbutton" @click="createStandardAssignment()">Upload Profile</a>
                    </div>
            </div>
                <div class="profileDetails">
                    <div class="profileDetailsContent">
                      <label class="light" style="font-size: 16px;">Course
                            Name</label>
                        <div class="value">
                            <span style="font-size: 18px">{{this.UserProfile.CourseName}}</span>
                        </div>
                    </div>
                    <div class="profileDetailsContent">
                      <label class="light" style="font-size: 16px;">Batch
                            Name</label>
                        <div> <span style="font-size: 18px">{{this.UserProfile.BatchName}}</span></div>
                    </div>
                    <div class="profileDetailsContent">
                      <label class="light" style="font-size: 16px;">Affiliation</label>
                        <div><span style="font-size: 18px">{{this.UserProfile.AffiliationName}}</span></div>
                    </div>
                    <div class="profileDetailsContent">
                      <label class="light" style="font-size: 16px;">Center</label>
                        <div><span style="font-size: 18px">{{this.UserProfile.CenterName}}</span></div>
                    </div>
                    <div class="profileDetailsContent">
                      <label class="light" style="font-size: 16px">Referral
                            Code</label>
                        <div><span style="font-size: 18px">{{this.UserProfile.referralcode}}</span></div>
                    </div>
                    <div class="profileDetailsContent">
                      <label class="light" style="font-size: 16px">Contact Email Id</label>
                        <div><span style="font-size: 18px">{{loginEmailId}}</span></div>
                    </div>
            </div>
        </div>
    </div>
    <div class="card-panel">
        <div style="padding:24px;">
            <div class="cardDetails" style="font-size: 25px">
                <p style="color: #6a2f85"><strong>Account Details</strong></p>
            </div>
            <div style="display:flex;flex-direction:row;flex-wrap:wrap;gap:15px;">
                <div class="eachField">
                    <label style="font-size: 18px; color: black">Name<i style="color: red">*</i></label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" v-model="UserProfile.Name" />
                    </div>
                    <div v-if="NameValidation" style="color: red; font-size: 16px">Please Enter a Name</div>
                </div>
                <div class="eachField">
                    <label style="font-size: 18px; color: black;">Mobile Number<i style="color: red">*</i></label>
                    <input type="text" class="browser-default UpdateProfile" style="background-color: rgb(239, 239, 239);" @change="MobileValidation=false" v-model="UserProfile.Mobile" readonly />
                    <div style="display:flex;flex-direction:row;justify-content:space-between;">
                    <label style="padisSentOtpEmailding: 6px;font-size: 14px;text-transform: uppercase;font-weight: 600;color: #003B93;outline: none;cursor:pointer;" @click="VerifyNewMobile=true, isSentOtp=false,isVerifyMobile=false">{{UserProfile.Mobile===null ?'Add Mobile':'Change Mobile'}}</label>
                    <label v-if="UserProfile.Mobile?.includes('(not verified)')" style="padding: 6px;font-size: 14px;text-transform: uppercase;font-weight: 600;color: #ee7800;;outline: none;cursor:pointer;" @click="VerifyNewMobile=true, isSentOtp=false, isVerifyMobile=true">Verify</label>
                    </div>
                    <div v-if="MobileValidation" style="color: red; font-size: 16px">Please Enter a Mobile No</div>
                    <!-- <div class="ChangeMobile" @click="ChangeMobileNumber()"> <a style="color: #6D2D7E;"><i class="material-icons">edit</i>Change Mobile</a></div> -->
                </div>
                <div class="eachField">
                    <label style="font-size: 18px; color: black">Contact Email Id</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" style="background-color: rgb(239, 239, 239);" @change="ContactEmailValidation=false" v-model="UserProfile.EmailId" readonly />
                        <div style="display:flex;flex-direction:row;justify-content:space-between;">
                        <label style="padding: 6px;font-size: 14px;text-transform: uppercase;font-weight: 600;color: #003B93;outline: none;cursor:pointer;" @click="VerifyNewEmail=true, isSentOtpEmail=false,isVarify=false">Change Email</label>
                        <label v-if="UserProfile.EmailId?.includes('(not verified)')" style="padding: 6px;font-size: 14px;text-transform: uppercase;font-weight: 600;color: #ee7800;;outline: none;cursor:pointer;" @click="VerifyNewEmail=true, isSentOtpEmail=false, isVarify=true">Verify</label>
                        </div>
                    </div>
                    <div v-if="ContactEmailValidation" style="color: red; font-size: 16px">Please Enter Contact EmailId</div>
                </div>
            </div>
            <div style="margin-bottom:20px;">
               <a style="margin:20px;text-decoration-line: underline;font-size:18px;" @click="otherInfo=!otherInfo">{{otherInfo?'Hide':'More details'}}</a>
            </div>
            <div v-if="otherInfo">
                 <div>
                      <label style="font-size: 18px; color: black">Gender</label>
                        <div style="display:flex;flex-direction:row;flex-wrap:wrap;gap:10px;padding:5px;">
                            <label>
                                <input class="with-gap" name="group1" :value="1" @change="UserProfile.GenderId=1" type="radio" :checked="UserProfile.GenderId==1" />
                                <span style="font-size: 20px; color: black">Male</span>
                            </label>
                            <label>
                                <input class="with-gap" name="group1" :value="2" type="radio" @change="UserProfile.GenderId=2" :checked="UserProfile.GenderId==2" />&nbsp;
                                <span style="font-size: 20px; color: black">Female</span>
                            </label>
                            <label>
                                <input class="with-gap" name="group1" :value="3" type="radio" @change="UserProfile.GenderId=3" :checked="UserProfile.GenderId==3" />&nbsp;
                                <span style="font-size: 20px; color: black">Other</span>
                            </label>
                        </div>
                    </div>
                <div style="display:flex;flex-direction;flex-wrap:wrap;gap:10px;">
                    <div  class="eachField">
                    <label style="font-size: 18px; color: black">Father</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" v-model="UserProfile.FatherName" style="width: 100%; border-radius: 5px" />
                    </div>
                </div>
                       <div class="eachField">
                    <label style="font-size: 18px; color: black">Date of Birth</label>
                    <div>
                        <input type="date" class="browser-default dobCss" v-model="UserProfile.DateOfBirth" style="width: 100%; border-radius: 5px" />
                    </div>
                    <!-- <div v-if="DOBValidation" style="color: red; font-size: 16px">Please input a value</div> -->
                </div>
                <div class="eachField">
                    <label style="font-size: 18px; color: black">Login Email Id</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" style="background-color: rgb(239, 239, 239);" v-model="loginEmailId" readonly />
                    </div>
                </div>
                          <div class="eachField">
                    <label style="font-size: 18px; color: black">State</label>
                    <select class="browser-default UpdateProfile input" v-model="UserProfile.StateId" @change="StateChange($event)">
                        <option value="" selected>-- select --</option>
                        <option v-for="states in StateName" :key="states.stateId" :value="states.stateId">
                            {{ states.stateName }}
                        </option>
                    </select>
                    <!-- <div v-if="SelectStateValidation" style="color: red; font-size: 18px">Please input a value</div> -->
                </div>
                   <div class="eachField">
                    <label style="font-size: 18px; color: black">City</label>
                    <select class="browser-default UpdateProfile" style="width: 100%; border-radius: 5px" v-model="UserProfile.CityId" @change="CityChange($event)">
                        <option value="" selected>-- select --</option>
                        <option v-for="data in CityName" :key="data.cityId" :value="data.cityId">{{data.cityName}}</option>
                    </select>
                    <!-- <div v-if="SelectCityValidation" style="color: red; font-size: 18px">Please input a value</div> -->
                </div>
                          <div class="eachField">
                    <label style="font-size: 18px; color: black">PinCode</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" v-model="UserProfile.PinCode" style="width: 100%; border-radius: 5px" />
                    </div>
                </div>
                   <div class="eachField">
                    <label style="font-size: 18px; color: black">Address1</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" v-model="addressLine1" style="width: 100%; border-radius: 5px" />
                    </div>
                </div>
                 <div class="eachField">
                    <label style="font-size: 18px; color: black">Address2</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" v-model="addressLine2" style="width: 100%; border-radius: 5px" />
                    </div>
                </div>
                  <div class="eachField">
                    <label style="font-size: 18px; color: black">Address3</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" v-model="addressLine3" style="width: 100%; border-radius: 5px" />
                    </div>
                </div>
                   <div class="eachField">
                    <label style="font-size: 18px; color: black">School Details</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" style="width: 100%; border-radius: 5px" v-model="UserProfile.SchoolName" />
                    </div>
                </div>
                       <div class="eachField">
                    <label style="font-size: 18px; color: black">Board</label>
                    <select class="browser-default UpdateProfile" style="width: 100%; border-radius: 5px" v-model="UserProfile.Board">
                        <option value="" selected>-- select --</option>
                        <option v-for="data in BoardDetails" :key="data.boardId" :value="data.boardName">{{data.boardName}}</option>
                    </select></div>
                       <div class="eachField">
                    <label style="font-size: 18px; color: black">Class</label>
                    <select class="browser-default UpdateProfile inputBox" style="font-size:18px;" v-model="UserProfile.Class">
                        <option v-for="studClass in classes" :value="studClass.value" :key="studClass.value">
                        {{ studClass.class }}
                    </option>
                    </select></div>
                        <div class="eachField">
                    <label style="font-size: 18px; color: black">Interest Area</label>
                    <div>
                        <input type="text" class="browser-default UpdateProfile" v-model="UserProfile.InterestArea" style="width: 100%; border-radius: 5px" />
                    </div>
                </div>
                  <div class="eachField">
                    <label style="font-size: 18px; color: black">Graduation Plan</label>
                    <div>
                        <select class="browser-default UpdateProfile inputBox" style="font-size:18px;" v-model="UserProfile.GraduationPlan">
                        <option v-for="grad in graduationPlan" :value="grad.value" :key="grad.value">
                        {{ grad.name }}
                    </option>
                    </select>
                    </div>
                </div>
                </div>
            </div>
            <div class="center">
                <a class="dbtn waves-effect waves-light btn getclassbutton" @click="UpdateUsersProfile()">Update Profile</a>
            </div>
        </div>
    </div>
    <Model2 :show="VerifyNewMobile" @close="VerifyNewMobile = false" :showHeader="true" :showCloseBtn="true">
        <template v-slot:header>
            <div class="nmb center" style="padding:5px;">
                    <h5>Change & Verify Mobile</h5>
            </div>
        </template>
        <template v-slot:body>
            <div v-if="!isSentOtp" style="padding:5px;">
                  <div v-if="!isVerifyMobile" class="center">
                <h6 style="font-weight">Enter new 10 digit mobile number</h6>
                <input type="Number" class="browser-default UpdateProfile" @change="NewMobileValidation=false" v-model="NewMobileNo" style="width: 60%; border-radius: 5px" />
                <div v-if="NewMobileValidation" style="color: red; font-size: 14px">{{NoValidationText}}</div>
                <div style="padding:10px;">
                    <input type="checkbox" class="filled-in" :checked="checkedMobile"><span @click="changeTick()">Receive updates and communication from SpEdLabs</span>
                </div>
                    <a class="NewMobile waves-effect waves-light btn getclassbutton" @click="SendOtp()">Send Otp</a>
                </div>
                 <div v-else class="center">
                <h6 style="font-weight">{{this.MobileNo}}</h6>
                    <a class="NewMobile waves-effect waves-light btn getclassbutton" @click="SendMobileOtpOnly()">Send Otp</a>
                </div>
            </div>
            <div v-if="isSentOtp" style="padding:5px;">
                <div class="center" v-if="otpTimer>0">
                    <h6 style="font-weight">4 digit OTP has been sent to your Mobile</h6>
                     <div id="divOuter">
                         <div id="divInner">
                          <input id="partitioned" v-model="otpText" type="text" maxlength="4" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==4) return false;"/>
                          <div v-if="NewOtpValidation" style="color: red; font-size: 14px">{{NewOtpValidationText}}</div>
                         </div>
                     </div>
                    <label style="font-weight;font-size:14px;">OTP expires in {{otpTimer}} seconds</label>
                    <div>
                        <a class="NewMobile waves-effect waves-light btn getclassbutton" @click="verifyOtp()">Verify</a></div>
                </div>
                <div class="center" v-else>
                    <img :src="require(`../assets/No-OTP.svg`)"/>
                    <div>
                        <label style="font-weight;font-size:14px;color:#000;">Didn't Receive the OTP? resend it again.</label>
                </div>
                <div>
                    <a class="waves-effect waves-light btn resendBtn" @click="resendOtp()">Resend OTP</a></div>
                </div>
            </div>
        </template>
    </Model2>
     <Model2 :show="VerifyNewEmail" @close="VerifyNewEmail = false" :showHeader="true" :showCloseBtn="true">
        <template v-slot:header>
            <div class="nmb center" style="padding:5px;">
                    <h5>Change & Verify Email</h5>
                </div>
        </template>
        <template v-slot:body>
            <div v-if="!isSentOtpEmail" style="padding:5px;">
                  <div v-if="!isVarify" class="center">
                <h6 style="font-weight">Enter New Email Id</h6>
                <input type="text" class="browser-default UpdateProfile" v-model="NewEmailId" style="width: 60%; border-radius: 5px" />
                <div v-if="NewEmailValidation" style="color: red; font-size: 14px">{{NewEmailValidationText}}</div>
                <div style="padding:10px;">
                    <input type="checkbox" class="filled-in" :checked="checkedMobile"><span @click="changeTick()">Receive updates and communication from SpEdLabs</span>
                </div>
                    <a class="NewMobile waves-effect waves-light btn getclassbutton" @click="SendOtpEmail()">Send Otp</a>
                </div>
                 <div v-else class="center">
                <h6 style="font-weight">{{ContactEmailId}}</h6>
                    <a class="NewMobile waves-effect waves-light btn getclassbutton" @click="SendOtpOnly()">Send Otp</a>
                </div>
            </div>
            <div v-if="isSentOtpEmail" style="padding:5px;">
                <div class="center" v-if="otpTimer>0">
                    <h6 style="font-weight">4 digit OTP has been sent to your Email</h6>
                     <div id="divOuter">
                         <div id="divInner">
                          <input id="partitioned" v-model="otpText" type="text" maxlength="4" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  onKeyPress="if(this.value.length==4) return false;"/>
                          <div v-if="NewOtpValidation" style="color: red; font-size: 14px">{{NewOtpValidationText}}</div>
                         </div>
                     </div>
                    <label style="font-weight;font-size:14px;">OTP expires in {{otpTimer}} seconds</label>
                    <div>
                        <a class="NewMobile waves-effect waves-light btn getclassbutton" @click="verifyOtp()">Verify</a></div>
                </div>
                <div class="center" v-else>
                    <img :src="require(`../assets/No-OTP.svg`)"/>
                    <div>
                        <label style="font-weight;font-size:14px;color:#000;">Didn't Receive the OTP? resend it again.</label>
                </div>
                <div>
                    <a class="waves-effect waves-light btn resendBtn" @click="resendOtpEmail()">Resend OTP</a></div>
                </div>
            </div>
        </template>
    </Model2>
</div>
</template>

<script>
// import Vue from "vue";
import Doubtapi from "../Doubtapi";
import MobileAPI from "../Mobileapi";
import Model2 from "../components/Model2.vue";

export default {
  data() {
    return {
      // UserProfile: {
      //   Name: "",
      //   DateOfBirth: null,
      //   ImageURL: "",
      //   AddressLine: "",
      //   AffiliationName: "",
      //   BatchName: "",
      //   Board: "",
      //   CenterName: "",
      //   CityId: "",
      //   Class: "",
      //   CourseName: "",
      //   CreatedOn: "",
      //   EmailId: "",
      //   FatherName: "",
      //   GenderId: 0,
      //   GraduationPlan: "",
      //   InterestArea: "",
      //   IsDummyEmailId: "",
      //   IsEmailIVerify: "",
      //   IsMobileVerify: "",
      //   Mobile: "",
      //   PinCode: "",
      //   SchoolName: "",
      //   StateId: "",
      //   UserId: "",
      //   UserProfileId: "",
      //   referralcode: "",
      //   ClassId: "",
      // },
      UserProfile: [],
      FileFavIcon: "",
      UploadIcon: "",
      NameValidation: false,
      DOBValidation: false,
      NumberValidation: false,
      SelectStateValidation: false,
      SelectCityValidation: false,
      VerifyNewMobile: false,
      VerifyNewEmail: false,
      MobileValidation: false,
      NewMobileValidation: false,
      StateName: [],
      CityName: [],
      CityNameAll: [],
      BoardDetails: [],
      UploadButton: false,
      //   UserProfile: "",
      otherInfo: false,
      ContactEmailId: "",
      MobileNo: null,
      ContactEmailValidation: false,
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      loginEmailId: "",
      checkedMobile: true,
      isSentOtp: false,
      isSentOtpEmail: false,
      otpTimer: 120,
      timeOut: 0,
      NewMobileNo: "",
      NewEmailId: "",
      NewEmailValidation: false,
      NewEmailValidationText: "",
      NoValidationText: "",
      isVarify: false,
      isVerifyMobile: false,
      otpText: "",
      otpRequestId: "",
      NewOtpValidationText: "",
      NewOtpValidation: false,
      classes: [
        {
          class: "6th",
          value: "6",
        },
        {
          class: "7th",
          value: "7",
        },
        {
          class: "8th",
          value: "8",
        },
        {
          class: "9th",
          value: "9",
        },
        {
          class: "10th",
          value: "10",
        },
        {
          class: "11th",
          value: "11 Math",
        },
        {
          class: "11th Biology",
          value: "11 Biology",
        },
        {
          class: "12th Math",
          value: "12 Math",
        },
        {
          class: "12th Biology",
          value: "12 Biology",
        },
      ],
      graduationPlan: [
        {
          name: "IIT",
          value: "IIT",
        },
        {
          name: "NEET",
          value: "NEET",
        },
        {
          name: "MHCET",
          value: "MHCET",
        },
      ],
    };
  },
  components: {
    Model2,
  },
  activated() {
    Doubtapi.getAllState({}, (response) => {
      if (response.statusCode === 400) {
        window.M.toast({
          html: `Error:${response.data.errorMessage}`,
          classes: "rounded error",
        });
        // console.error("There was an error!", response.data.errorMessage);
      } else if (response.statusCode === 200) {
        this.StateName = response.data;
      }
    });

    Doubtapi.getAllCity({}, (response) => {
      if (response.statusCode === 400) {
        window.M.toast({
          html: `Error:${response.data.errorMessage}`,
          classes: "rounded error",
        });
        // console.error("There was an error!", response.data.errorMessage);
      } else if (response.statusCode === 200) {
        this.CityNameAll = response.data;
        // console.log("getCityName", this.CityName);
      }
    });
    MobileAPI.getUserProfile((response) => {
      this.UserProfile = response.data;
      this.ContactEmailId = this.UserProfile?.EmailId.includes("(not verified)") ? this.UserProfile?.EmailId.replace("(not verified)", "") : this.UserProfile?.EmailId;
      this.loginEmailId = this.UserProfile?.EmailId.includes("(not verified)") ? this.UserProfile?.EmailId.replace("(not verified)", "") : this.UserProfile?.EmailId;
      this.MobileNo = this.UserProfile.Mobile?.includes("(not verified)") ? this.UserProfile.Mobile?.replace("(not verified)", "") : this.UserProfile.Mobile;
      this.addressLine1 = this.UserProfile.AddressLine;
      this.addressLine2 = this.UserProfile.AddressLine;
      this.addressLine3 = this.UserProfile.AddressLine;
      // console.log("UserProfileData", this.UserProfile);
      this.FileFavIcon = this.UserProfile.ImageURL;
    });
    MobileAPI.getBoardDetails((response) => {
      this.BoardDetails = response.data;
      // console.log("BoardDetails", response.data);
    });
  },
  methods: {
    ValidateMobile() {
      if (this.NewMobileNo.length < 10 || this.NewMobileNo.length > 10) {
        this.NoValidationText = "Mobile Number should be 10 digit";
        this.NewMobileValidation = true;
      } else {
        this.NewMobileValidation = false;
      }
    },
    ValidateEmail() {
      if (this.NewEmailId === "") {
        this.NewEmailValidationText = "Please Enter Email Id.";
        this.NewEmailValidation = true;
      } else if (this.NewEmailId === this.ContactEmailId) {
        this.NewEmailValidationText = "Email Id is already exists to another user.";
        this.NewEmailValidation = true;
      } else {
        const patt = /(.+)@(.+){2,}\.(.+){2,}/;
        if (!patt.test(this.NewEmailId)) {
          this.NewEmailValidation = true;
          this.NewEmailValidationText = "Please enter a valid email.";
        } else {
          this.NewEmailValidation = false;
        }
      }
    },
    countDownTimer() {
      clearTimeout(this.timeOut);
      if (this.otpTimer > 0) {
        this.timeOut = setTimeout(() => {
          this.otpTimer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    resendOtp() {
      this.otpTimer = 120;
      this.countDownTimer();
      this.SendOtp();
    },
    SendOtpEmail() {
      this.ValidateEmail();
      if (!this.NewEmailValidation) {
        const data = {
          email: this.NewEmailId,
        };
        MobileAPI.verifyEmail(data, (response) => {
          if (response.responseCode === 200) {
            if (response.data.otpId) {
              this.otpRequestId = response.data.otpId;
              this.otpTimer = 120;
              this.isSentOtpEmail = true;
              this.countDownTimer();
            }
            if (response.data.info) {
              this.NewEmailValidationText = "Email is already exists to another user.";
              this.NewEmailValidation = true;
            }
          }
        });
      }
    },
    SendOtpOnly() {
      this.otpTimer = 120;
      this.isSentOtpEmail = true;
      this.countDownTimer();
    },
    SendMobileOtpOnly() {
      this.otpTimer = 120;
      this.isSentOtp = true;
      this.countDownTimer();
    },
    resendOtpEmail() {
      this.otpTimer = 120;
      this.countDownTimer();
      this.SendOtpEmail();
    },
    changeTick() {
      this.checkedMobile = !this.checkedMobile;
    },
    StateChange(event) {
      this.state = Number(event.target.value);
      this.CityName = this.CityNameAll.filter((city) => city.stateId === this.state);
      this.SelectStateValidation = false;
    },
    CityChange(event) {
      this.city = event.target.value;
      this.SelectCityValidation = false;
    },
    ChangeMobileNumber() {
      this.VerifyNewMobile = true;
      this.NewMobileValidation = false;
    },
    SendOtp() {
      this.ValidateMobile();
      if (!this.NewMobileValidation) {
        const data = {
          mobile: this.NewMobileNo,
        };
        MobileAPI.verifyMobile(data, (response) => {
          if (response.responseCode === 200) {
            if (response.data.otpId) {
              this.otpRequestId = response.data.otpId;
              this.otpTimer = 120;
              this.isSentOtp = true;
              this.countDownTimer();
            }
            if (response.data.info) {
              this.NoValidationText = "Mobile number is already exists to another user.";
              this.NewMobileValidation = true;
            }
          }
        });
      }
    },
    verifyOtp() {
      if (this.otpText === null) {
        this.NewOtpValidationText = "Please Enter OTP.";
        this.NewOtpValidation = true;
      } else if (this.otpText?.length < 4) {
        this.NewOtpValidationText = "Please Enter 4 digits.";
        this.NewOtpValidation = true;
      } else {
        this.NewOtpValidation = false;
        const data = {
          otpRequestId: this.otpRequestId,
          otp: this.otpText,
        };
        MobileAPI.validateOtp(data, (response) => {
          if (response.responseCode === 200) {
            if (response.message === "Success") {
              this.NewOtpValidation = false;
              window.location.reload();
            } else {
              this.NewOtpValidationText = "Invalid OTP";
              this.NewOtpValidation = true;
            }
          }
        });
      }
    },
    // validation() {
    //   if (this.UserProfile.Name === "") {
    //     this.NameValidation = true;
    //   }
    //   if (this.UserProfile.Mobile === "") {
    //     this.MobileValidation = true;
    //   }
    // },
    UpdateUsersProfile() {
      if (this.UserProfile.Name === "") {
        this.NameValidation = true;
      }
      if (this.UserProfile.Mobile === "") {
        this.MobileValidation = true;
      }

      if (this.UserProfile.Name !== "" && this.UserProfile.Mobile !== "") {
        const data = {
          UserId: this.UserProfile.UserId,
          GenderId: this.UserProfile.GenderId,
          DateOfBirth: this.UserProfile.DateOfBirth,
          EmailId: this.UserProfile.EmailId,
          Mobile: this.UserProfile.Mobile,
          Name: this.UserProfile.Name,
          Board: this.UserProfile.Board,
          Class: this.UserProfile.Class,
          FatherName: this.UserProfile.FatherName,
          InterestArea: this.UserProfile.InterestArea,
          GraduationPlan: this.UserProfile.GraduationPlan,
          PinCode: this.UserProfile.PinCode,
          SchoolName: this.UserProfile.SchoolName,
          AddressLine: `${this.addressLine1},${this.addressLine2},${this.addressLine3}`,
          CityId: this.UserProfile.CityId,
          StateId: this.UserProfile.StateId,
          ClassId: this.UserProfile.ClassId,
          ImageURL: this.pdfassignmentId,
        };
        MobileAPI.UpdateUserProfile(data, (response) => {
          this.Updateuserprofile = response.data;
          if (response.responseCode === 200) {
            window.M.toast({
              html: "Profile Updated Successfully",
              classes: "rounded green",
            });
          }
        });
      }
    },
    createIconimage(file) {
      const reader = new FileReader();
      const vm = this;

      reader.onload = (e) => {
        vm.FileFavIcon = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onUploadFile(e) {
      this.file = e.target.files || e.dataTransfer.files;
      // console.log(this.file[0].name);
      //   this.UploadIcon = this.file[0];
      this.createIconimage(this.file[0]);
      this.UploadButton = true;
    },
    createStandardAssignment() {
      this.UploadButton = false;
      const UserId = this.$store.getters.user.UserId;
      const fileField1 = document.getElementById("file1");
      const formData = new FormData();
      formData.append("file", fileField1.files[0]);
      // console.log("formdata", formData);
      MobileAPI.UploadUserProfile(UserId, formData, (response) => {
        this.pdfassignmentId = response.data.dbUrl;
        // console.log(this.pdfassignmentId);
        // this.UserProfile.Mobile = "";
      });
    },
    removeIcon() {
      this.FileFavIcon = this.UserProfile.ImageURL;
      //   this.UploadIcon = null;
      // this.$refs.inputFile.value = null;
    },
  },
};
</script>

<style scoped>
[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:before,
[type="radio"].with-gap:checked+span:after {
    border: 2px solid #f59e00;
}

[type="radio"]:checked+span:after,
[type="radio"].with-gap:checked+span:after {
    background-color: #f59e00;
}

.headerText {
    margin-top: 19px;
    font-size: 30px;
    color: #642c90;
}

.dobCss {
    box-sizing: border-box;
    /* width: 486px; */
    height: 50px;
    border: 1px solid #E0E4F0;
    border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.dropd {
    /* width: 200px; */
    height: 40px;
}

.dbtn {
    width: 144px;
    height: 36px;
    margin-top: 26px;
    background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.User-icon {
    width: 200px;
    height: 200px;
}

.UpdateProfile {
    height: 50px;
    width: 100%;
    border-radius: 5px;
}

.ChangeMobile {
    display: flex;
    font-size: 18px;
    text-decoration-line: underline;
}

.ChangeProfile {
    font-size: 20px;
    text-decoration-line: underline;
    color: #6D2D7E;
    cursor: pointer;
}

.NewMobile {
    margin-bottom: 10px;
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 10px;
}
.resendBtn {
    color: #FF9421;
    background-color: white;
    border-radius: 5px;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 10px;
    border: 1px solid #FF9421;
    margin-bottom: 10px;
}

.UserProfile {
    background: linear-gradient(90.2deg, #FF9421 0.16%, #FBAD26 98.41%);
    border-radius: 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 36px;
}

.icon {
    font-size: 17px;
    text-decoration-line: underline;
}

h6 {
    font-weight: 500;
}

.UploadProfileButton {
    justify-content: center;
    text-align: center;
    margin-left: 20%;
    margin-top: 15px
}

.ViewMoreBtn {
    cursor: pointer;
    padding: 8px 10px;
    background: #6a2f85;
    border-radius: 5px;
    color: #ffff;
    border: none;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.card-panel {
    padding: 0px;
}

.row {
    margin-bottom: 0px;
}

@media screen and (max-width: 1000px) {
    .headerText {
        margin-left: 19px;
    }
    .myProfDetails{
     border-bottom:1px solid #dedede;
     width: 50% !important;
    }
    .eachField{
      width: 48% !important;
    }
    .profileDetailsContent{
      width: 49% !important;
    }
}
@media screen and (max-width: 650px) {
    .eachField{
      width: 95% !important;
    }
    .myProfDetails{
      width: 50% !important;
    }
    .profileDetailsContent{
      width: 95% !important;
    }
}
@media screen and (max-width: 450px) {
  .myProfDetails{
      width: 100% !important;
    }
    .profile{
      flex-direction: column !important;
    }
}

#partitioned {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  min-width: 220px;
}

#divInner{
  left: 0;
  position: sticky;
}

#divOuter{
  width: 190px;
  overflow: hidden;
  margin: 0 auto;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.profile{
display:flex;
flex-direction:row;
}

.myProfDetails{
  border-right: 1px solid #dedede;
  padding:24px;
  width: 28%;
}
.profileDetails{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  line-height: 1.5;
  padding:24px;
}
.eachField{
  width: 32%;
}
.profileDetailsContent{
margin-top:10px;
width:25%;
}
.modalinputfiled{
  padding: auto;
}
</style>
